import React, { useEffect, useState } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import axios from "axios";
import { useStateContext } from "../contexts/ContextProvider";
import { baseUrl } from "./../data/constant";
import { toast } from "react-toastify";

const StateTax = () => {
  const { currentColor } = useStateContext();
  const [open, setOpen] = useState(false);
  const [states, setStates] = useState([]);
  const [newState, setNewState] = useState({
    stateName: "",
    taxPercentage: "",
  });

  useEffect(() => {
    fetchStates();
  }, []);

  const fetchStates = async () => {
    const token = localStorage.getItem("token");
    try {
      const { data } = await axios.get(`${baseUrl}/state-tax`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.success) {
        setStates(data.data);
      }
    } catch (error) {
      console.error("Error adding state tax:", error);
      toast.error("error");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "stateName") {
      const formattedValue = value.toUpperCase();
      setNewState({ ...newState, [name]: formattedValue });
    } else {
      setNewState({ ...newState, [name]: value });
    }
  };

  const handleSubmit = async () => {
    const token = localStorage.getItem("token");
    try {
      const { data } = await axios.post(`${baseUrl}/state-tax`, newState, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.success) {
        toast.success("State added");
        fetchStates();
      }
      setNewState({ stateName: "", taxPercentage: "" });
      setOpen(false);
    } catch (error) {
      console.error("Error adding state tax:", error);
      toast.error("error");
    }
  };

  const handleDelete = async (id) => {
    try {
      const token = localStorage.getItem("token");
      const { data } = await axios.delete(`${baseUrl}/state-tax/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.success) {
        toast.success("State Deleted");
        fetchStates();
      }
    } catch (error) {
      console.error("Error deleting state tax:", error);
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center w-full mb-10">
        <h1
          style={{
            color: currentColor,
          }}
          className="ml-2 text-xl md:text-4xl font-medium title-font tracking-widest uppercase"
        >
          Manage States Tax
        </h1>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpen(true)}
        >
          Add State Tax
        </Button>
      </div>

      {/* Table Section */}
      <TableContainer component={Paper} style={{ padding: 20 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>State Name</strong>
              </TableCell>
              <TableCell>
                <strong>Tax Percentage</strong>
              </TableCell>
              <TableCell>
                <strong>Created By</strong>
              </TableCell>
              <TableCell>
                <strong>Actions</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {states.map((state) => (
              <TableRow key={state.id}>
                <TableCell>{state.stateName}</TableCell>
                <TableCell>{state.taxPercentage}%</TableCell>
                <TableCell>{state.created_by.name}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: "#1975d1" }}
                    onClick={() => handleDelete(state._id)}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Modal for Adding State */}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Add State Tax</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="stateName"
            label="State Name"
            type="text"
            fullWidth
            value={newState.stateName}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="taxPercentage"
            label="Tax Percentage"
            type="number"
            fullWidth
            value={newState.taxPercentage}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default StateTax;
